import React from 'react';

const UxUiUsabilityTesting = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.84147 28.648C8.66518 28.648 8.49551 28.5779 8.37085 28.4533C8.24619 28.3286 8.17676 28.1596 8.17676 27.9833V11.8971C8.17676 11.192 8.45644 10.5159 8.95507 10.0172C9.4537 9.51861 10.1304 9.23828 10.8356 9.23828H36.8918C37.597 9.23828 38.2737 9.51861 38.7723 10.0172C39.271 10.5159 39.5506 11.192 39.5506 11.8971V27.2653C39.5506 27.4416 39.4805 27.6107 39.3559 27.7353C39.2312 27.86 39.0622 27.9301 38.8859 27.9301C38.7096 27.9301 38.5406 27.86 38.4159 27.7353C38.2913 27.6107 38.2212 27.4416 38.2212 27.2653V11.8971C38.2213 11.5138 38.071 11.146 37.8025 10.8725C37.534 10.599 37.1692 10.4419 36.786 10.435H10.7291C10.3459 10.4419 9.98048 10.599 9.71196 10.8725C9.44343 11.146 9.2932 11.5138 9.29327 11.8971V27.9833C9.30083 28.1282 9.26064 28.2716 9.17902 28.3916C9.0974 28.5116 8.97905 28.6017 8.84147 28.648Z"
      fill="#475467"
    />
    <path
      d="M31.5229 31.6295H16.5539C16.397 31.5983 16.2561 31.5136 16.1546 31.3899C16.0532 31.2663 15.9976 31.1114 15.9976 30.9515C15.9976 30.7915 16.0532 30.6366 16.1546 30.513C16.2561 30.3893 16.397 30.3047 16.5539 30.2734H31.5229C31.6797 30.3047 31.8213 30.3893 31.9227 30.513C32.0242 30.6366 32.0798 30.7915 32.0798 30.9515C32.0798 31.1114 32.0242 31.2663 31.9227 31.3899C31.8213 31.5136 31.6797 31.5983 31.5229 31.6295Z"
      fill="#475467"
    />
    <path
      d="M12.6647 28.3195C12.4884 28.3195 12.3194 28.2494 12.1947 28.1248C12.0701 28.0001 12 27.8311 12 27.6548V10.6647C12 10.4884 12.0701 10.3194 12.1947 10.1947C12.3194 10.0701 12.4884 10 12.6647 10C12.841 10 13.01 10.0701 13.1347 10.1947C13.2593 10.3194 13.3294 10.4884 13.3294 10.6647V27.5483C13.345 27.6441 13.3396 27.7422 13.3132 27.8356C13.2868 27.9289 13.2402 28.0152 13.1769 28.0887C13.1135 28.1622 13.0349 28.2209 12.9464 28.2607C12.858 28.3006 12.7617 28.3207 12.6647 28.3195Z"
      fill="#475467"
    />
    <path
      d="M33.4901 25.9995C33.3601 25.9995 33.2355 25.932 33.1436 25.812C33.0517 25.692 33 25.5292 33 25.3595V10.64C33 10.4702 33.0517 10.3075 33.1436 10.1875C33.2355 10.0675 33.3601 10 33.4901 10C33.6201 10 33.7447 10.0675 33.8367 10.1875C33.9286 10.3075 33.9803 10.4702 33.9803 10.64V25.1804C34.0021 25.2779 34.0059 25.3807 33.9913 25.4804C33.9766 25.5802 33.9439 25.6738 33.896 25.7539C33.8481 25.8339 33.7862 25.8981 33.7156 25.9407C33.645 25.9834 33.5679 26.0036 33.4901 25.9995Z"
      fill="#475467"
    />
    <path
      d="M36.8422 22.5034C36.6659 22.5034 36.4969 22.4336 36.3722 22.3089C36.2476 22.1843 36.1775 22.0149 36.1775 21.8386V18.8874C36.1775 18.7111 36.2476 18.5421 36.3722 18.4174C36.4969 18.2927 36.6659 18.2227 36.8422 18.2227C37.0185 18.2227 37.1882 18.2927 37.3128 18.4174C37.4375 18.5421 37.5069 18.7111 37.5069 18.8874V21.8386C37.5069 22.0149 37.4375 22.1843 37.3128 22.3089C37.1882 22.4336 37.0185 22.5034 36.8422 22.5034Z"
      fill="#475467"
    />
    <path
      d="M1.63361 39C1.46557 39 1.30445 38.9302 1.18563 38.8055C1.0668 38.6808 1 38.5114 1 38.335C1.08357 34.0974 1.94405 29.9162 3.53443 26.0201C3.6865 25.6211 3.81357 25.302 3.8896 25.0626C4.42183 23.3603 7.53904 22.2432 8.17265 22.0304C8.33397 21.9775 8.50865 21.9942 8.65837 22.0765C8.80809 22.1588 8.92043 22.3001 8.97085 22.4694C9.02126 22.6387 9.00596 22.822 8.92753 22.9791C8.84911 23.1362 8.71389 23.2545 8.55257 23.3074C7.26 23.7329 5.33416 24.7169 5.08071 25.4883C5.08071 25.7277 4.8782 26.0734 4.70079 26.499C3.15044 30.2407 2.29043 34.257 2.16636 38.335C2.16831 38.4944 2.11555 38.6494 2.01786 38.7714C1.92017 38.8935 1.78354 38.9745 1.63361 39Z"
      fill="#475467"
    />
    <path
      d="M8.71161 39.4418C8.53532 39.4418 8.3663 39.3721 8.24164 39.2474C8.11698 39.1228 8.0469 38.9534 8.0469 38.7771V37.5542C8.04589 37.4412 8.07458 37.33 8.13064 37.2319C8.1867 37.1338 8.26777 37.0524 8.36562 36.9959C10.1616 35.8293 11.693 34.2983 12.8596 32.5023C13.8888 30.6347 15.0712 28.856 16.3954 27.1846C16.228 26.8545 15.9435 26.5985 15.5976 26.4666C15.3342 26.4727 15.0755 26.5381 14.8407 26.6578C14.606 26.7775 14.4012 26.9483 14.2416 27.158L13.8696 27.5302C12.8647 28.712 11.6469 29.6953 10.2799 30.4286C10.13 30.4964 9.96017 30.5061 9.80345 30.4559C9.64673 30.4057 9.51421 30.2992 9.4315 30.157C9.34879 30.0147 9.32227 29.8467 9.3562 29.6857C9.39013 29.5247 9.48212 29.382 9.6152 29.2852C10.8808 28.6163 12.0084 27.7142 12.9388 26.6263L13.3114 26.2541C13.624 25.8623 14.0343 25.5601 14.5012 25.3781C14.9681 25.196 15.475 25.1404 15.9702 25.2171C16.4114 25.3561 16.8154 25.5935 17.1516 25.9113C17.4878 26.2291 17.7475 26.6191 17.9111 27.0518C17.9745 27.156 18.0078 27.2756 18.0078 27.3975C18.0078 27.5194 17.9745 27.639 17.9111 27.7432C16.5349 29.4051 15.3068 31.1844 14.2416 33.0609C13.0548 34.9666 11.467 36.5905 9.58859 37.82V38.6976C9.60936 38.8123 9.6 38.9303 9.56068 39.04C9.52136 39.1498 9.45353 39.2473 9.36464 39.3227C9.27575 39.3981 9.16904 39.4488 9.05435 39.4697C8.93967 39.4907 8.82141 39.481 8.71161 39.4418Z"
      fill="#475467"
    />
    <path
      d="M46.2786 39.4463C46.1023 39.4463 45.9333 39.3765 45.8086 39.2519C45.684 39.1272 45.6139 38.9579 45.6139 38.7816C45.4837 34.705 44.5815 30.6902 42.955 26.9498C42.7689 26.5244 42.6362 26.1788 42.5565 25.9395C42.2906 25.1684 40.2164 24.1848 38.9135 23.7594C38.8301 23.7353 38.7528 23.6944 38.6857 23.6393C38.6186 23.5842 38.5632 23.5158 38.5234 23.4387C38.4836 23.3615 38.46 23.277 38.4539 23.1904C38.4479 23.1038 38.4596 23.0169 38.4883 22.935C38.5095 22.8491 38.5481 22.7687 38.6019 22.6987C38.6558 22.6286 38.7237 22.5704 38.8012 22.5279C38.8787 22.4855 38.9644 22.4594 39.0524 22.4517C39.1405 22.4439 39.229 22.4545 39.3127 22.4828C39.9775 22.6955 43.2477 23.8123 43.806 25.514C43.806 25.7533 44.0185 26.0723 44.178 26.4711C45.8464 30.3658 46.7492 34.5454 46.8368 38.7816C46.8389 38.9409 46.7842 39.0958 46.6817 39.2178C46.5792 39.3398 46.4359 39.4208 46.2786 39.4463Z"
      fill="#475467"
    />
    <path
      d="M39.1289 39.4479C38.9526 39.4479 38.7836 39.3782 38.6589 39.2535C38.5343 39.1289 38.4642 38.9595 38.4642 38.7832V37.9059C36.5797 36.6839 34.9904 35.0581 33.8112 33.1465C32.7459 31.2701 31.5185 29.4907 30.1423 27.8288C30.0789 27.7246 30.0449 27.605 30.0449 27.4831C30.0449 27.3612 30.0789 27.2416 30.1423 27.1375C30.306 26.7047 30.5656 26.315 30.9018 25.9973C31.238 25.6795 31.642 25.442 32.0832 25.303C32.5784 25.2264 33.0846 25.2819 33.5515 25.464C34.0184 25.6461 34.4295 25.9483 34.742 26.34L35.114 26.7123C36.0443 27.8001 37.172 28.7023 38.4376 29.3711C38.5706 29.4679 38.6633 29.6106 38.6972 29.7716C38.7311 29.9327 38.704 30.1003 38.6213 30.2426C38.5386 30.3848 38.406 30.4913 38.2493 30.5415C38.0926 30.5917 37.9228 30.582 37.7728 30.5142C36.4058 29.7809 35.1881 28.798 34.1831 27.6162L33.8112 27.2439C33.6503 27.0357 33.4452 26.8656 33.2107 26.746C32.9763 26.6265 32.7182 26.5605 32.4552 26.5526C32.1093 26.6844 31.8248 26.9404 31.6574 27.2705C32.9816 28.942 34.1646 30.7207 35.1938 32.5882C36.3604 34.3842 37.8911 35.9153 39.6871 37.0818C39.785 37.1383 39.8661 37.2197 39.9221 37.3178C39.9782 37.4159 40.0075 37.5271 40.0065 37.6401V38.8631C39.9997 38.9648 39.9693 39.0637 39.9182 39.1519C39.8672 39.2401 39.7966 39.3154 39.7118 39.372C39.627 39.4285 39.5305 39.4646 39.4294 39.4778C39.3284 39.491 39.2254 39.4808 39.1289 39.4479Z"
      fill="#475467"
    />
  </svg>
);

export default UxUiUsabilityTesting;
